import axios from '@/utils/request'
/**
 * 获取部门组织结构树
 *  @param {user_id ,ent_id}
 */
const query_dept_staff_tree_aim = (data) => axios.post('/query_dept_staff_tree_aim_not', {
    ...data
})

// 判断是否注册过
const register_judge = (data) => axios.post('/register_judge', {
    ...data
})

// 验证码
const getCode = (data) => axios.post('/sms', {
    ...data
})

// 注册
const register = (data) => axios.post('/register', {
    ...data
})

// 登陆
const pwd_login = (data) => axios.post('/pwd_login', {
    ...data
})

// 获取我的基础信息
const get_my_info = (data) => axios.post('/get_my_info', {
    ...data
})

// 获取当前是否有企业
const get_rel_list = (data) => axios.post('/get_rel_list', {
    ...data
})

// 登陆日志
const insert_m_login_log = (data) => axios.post('/insert_m_login_log', {
    ...data
})

// 忘记密码
const forget_user_pwd = (data) => axios.post('/forget_user_pwd', {
    ...data
})

// 查询店铺信息列表
const query_shop_info_list = (data) => axios.post('/query_shop_info_list', {
    ...data
})

// 添加企业认证信息
const add_ent_aut_info = (data) => axios.post('/add_ent_aut_info', {
    ...data
})

// 认证企业前判断uscc是否已注册企业
const aut_ent_have_exist = (data) => axios.post('/aut_ent_have_exist', {
    ...data
})

//
const query_user_aut_ent_list = (data) => axios.post('/query_user_aut_ent_list', {
    ...data
})

// 退出登陆
const logout = (data) => axios.post('/logout', {
    ...data
})

// 获取用户相关的企业列表
const query_user_ent_list = (data) => axios.post('/query_user_ent_list', {
    ...data
})

// 解散当前企业
const dismiss_aut_ent = (data) => axios.post('/dismiss_aut_ent', {
    ...data
})

// 删除当前企业
const delete_un_aut_ent = (data) => axios.post('/delete_un_aut_ent', {
    ...data
})

// 修改企业认证信息
const update_ent_aut_info = (data) => axios.post('/update_ent_aut_info', {
    ...data
})

// query_user_aut_ent
const query_user_aut_ent = (data) => axios.post('/query_user_aut_ent', {
    ...data
})

// 导入查询
const query_import_log_list = (data) => axios.post('/query_import_log_list', {
    ...data
})

// 新增桌台信息
const insert_table_info = (data) => axios.post('/insert_table_info', {
    ...data
})

// 查询桌台列表
const query_table_info_list = (data) => axios.post('/query_table_info_list', {
    ...data
})

// 判断是否已经存在桌台名称
const have_exist_table_name = (data) => axios.post('/have_exist_table_name', {
    ...data
})

// delete_table_info
const delete_table_info = (data) => axios.post('/delete_table_info', {
    ...data
})

// update_table_info 修改桌台信息
const update_table_info = (data) => axios.post('/update_table_info', {
    ...data
})

// 查询子管理员列表
const query_ent_sub_manager_list = (data) => axios.post('/query_ent_sub_manager_list', {
        ...data
    })
    // 查询主管理员详情
const query_ent_primary_manager_detail = (data) => axios.post('/query_ent_primary_manager_detail', {
        ...data
    })
    // 获取系统日志列
const get_system_logs_list = (data) => axios.post('/get_system_logs_list', {
    ...data
})

// 查询工作日方案列表
const query_workday_plan_list = (data) => axios.post('/query_workday_plan_list', {
    ...data
})

// 新增工作日方案
const insert_workday_plan = (data) => axios.post('/insert_workday_plan', {
    ...data
})

// 删除工作日方案
const delete_workday_plan = (data) => axios.post('/delete_workday_plan', {
    ...data
})

// 新增工作日方案门店
const insert_workday_plan_shop = (data) => axios.post('/insert_workday_plan_shop', {
    ...data
})

// 删除工作日方案门店
const delete_workday_plan_shop = (data) => axios.post('/delete_workday_plan_shop', {
    ...data
})

//query_category_info_list  查询菜品分类
const query_category_info_list = (data) => axios.post('/query_category_info_list', {
        ...data
    })
    // 批量修改产品类型顺序
const update_category_info = (data) => axios.post('/update_category_info', {
        ...data
    })
    // /query_ent_prod_cat_list_tree_of_parent // 查询父节点下的企业产品类型（只有类型，没有产品）列表 --树状图
const query_ent_prod_cat_list_tree_of_parent = (data) => axios.post('/query_ent_prod_cat_list_tree_of_parent', {
    ...data
})


//查询产品列表
const query_cat_list_active_0 = (data) => axios.post('/query_cat_list_active_0', {
    ...data
})


// 查询工作日设置列表
const query_workday_set_list = (data) => axios.post('/query_workday_set_list', {
    ...data
})

// 更新工作日设置
const update_workday_set = (data) => axios.post('/update_workday_set', {
    ...data
})

// 删除工作日设置
const delete_workday_set = (data) => axios.post('/delete_workday_set', {
    ...data
})

// 新增工作日设置
const insert_workday_set = (data) => axios.post('/insert_workday_set', {
    ...data
})

//修改产品种类
const update_prod_category_active_batch = (data) => axios.post('/update_prod_category_active_batch', {
        ...data
    })
    //批量修改产品品类启用停用状态
const query_stop_store_house_list = (data) => axios.post('/query_stop_store_house_list', {
    ...data
})


const insert_staff = (data) => axios.post('/insert_staff', {
    ...data
})

const update_staff = (data) => axios.post('/update_staff', {
    ...data
})
const update_staff_active_batch = (data) => axios.post('/update_staff_active_batch', {
        ...data
    })
    /**
     * 模块权限
     * @param {dept_ids: 部门名称, ent_id, module_id:模块名称，read_flag："1',role_ids :'',staff_ids:'',type:'1'

     */
const set_aim_module_power = (data) => axios.post('/set_aim_module_power', {...data })
    /**
     * 获取模块权限
     */
const query_aim_module_power_detail = (data) => axios.post('/query_aim_module_power_detail', {...data })

const toggle_user_ent = (data) => axios.post('/toggle_user_ent', {...data })
const query_dept_tree = (data) => axios.post('/query_dept_tree', {...data })

const batch_set_dept_for_many_staff = (data) => axios.post('/batch_set_dept_for_many_staff', {...data })

const query_dept_staff_list = (data) => axios.post('/query_dept_staff_list', {...data })
const query_staff_list_of_dept_2 = (data) => axios.post('/query_staff_list_of_dept_2', {...data })
    // 获取各个模块权限

const query_user_module_list = (data) => axios.post('/query_user_module_list', {...data })
    // 设置各个模块权限 --- 新增
const set_user_module_rel = (data) => axios.post('/set_user_module_rel', {...data })
    // 更新个模块权限
const update_user_module = (data) => axios.post('/update_user_module', {...data })
    // 删除某人的所有权限
const delete_user_module = (data) => axios.post('/delete_user_module', {...data })
const insert_rule_comm = (data) => axios.post('/insert_rule_comm', {...data })
const query_rule_comm_list = (data) => axios.post('/query_rule_comm_list', {...data })
const delete_rule_comm = (data) => axios.post('/delete_rule_comm', {...data })
const query_shop_status_list_of_table = (data) => axios.post('/query_shop_status_list_of_table', {...data })
const update_rule_comm = (data) => axios.post('/update_rule_comm', {...data })


const query_import_log_user_list = (data) => axios.post('/query_import_log_user_list', {...data })   // 导入查询
const query_operate_log_user_list = (data) => axios.post('/query_operate_log_user_list', {...data }) // 操作管理


const export_table_qrcode_zip_post = (data) => axios.post('/export_table_qrcode_zip_post', {...data }) // 下载桌子二维码


const query_report_info_list = (data) => axios.post('/query_report_info_list', {...data }) // 下载桌子二维码
const set_user_report_rel = (data) => axios.post('/set_user_report_rel', {...data }) // 下载桌子二维码

const query_user_report_list = (data) => axios.post('/query_user_report_list', {...data }) // 下载桌子二维码
const update_user_report = (data) => axios.post('/update_user_report', {...data }) // 下载桌子二维码

const delete_user_report = (data) => axios.post('/delete_user_report', {...data }) // 下载桌子二维码

// get_wxpay_qrcode
const get_wxpay_qrcode = (data) => axios.post('/get_wxpay_qrcode', {...data , responseType: 'arraybuffer'}) // 微信支付统一下单-生成二维码

// const get_wxpay_qrcode = (params) => axios.get('/get_wxpay_qrcode', {
//     params: params,
//     responseType: 'blob' // 必须说明 axios 请求类型
//   }) // 微信支付统一下单-生成二维码

const lun_xun_trade_no = (data) => axios.post('/lun_xun_trade_no', {...data }) // 下载桌子二维码

const insert_order_web = (data) => axios.post('/insert_order_web', {...data }) // 



// 快跑
export {
    delete_user_report,
    update_user_report,
    query_user_report_list,
    set_user_report_rel,
    query_report_info_list,
    query_operate_log_user_list,
    query_import_log_user_list,
    query_shop_status_list_of_table,
    delete_rule_comm,
    update_rule_comm,
    insert_rule_comm,
    query_rule_comm_list,
    query_dept_staff_list,
    query_staff_list_of_dept_2,
    batch_set_dept_for_many_staff,
    query_dept_tree,
    toggle_user_ent,
    update_staff_active_batch,
    update_staff,
    query_cat_list_active_0,
    query_stop_store_house_list,
    update_prod_category_active_batch,
    update_category_info,
    query_category_info_list,
    query_dept_staff_tree_aim,
    register_judge,
    getCode,
    register,
    pwd_login,
    get_my_info,
    get_rel_list,
    insert_m_login_log,
    forget_user_pwd,
    query_shop_info_list,
    logout,
    add_ent_aut_info,
    query_user_aut_ent_list,
    aut_ent_have_exist,
    query_user_ent_list,
    dismiss_aut_ent,
    delete_un_aut_ent,
    update_ent_aut_info,
    query_user_aut_ent,
    query_import_log_list,
    insert_table_info,
    query_table_info_list,
    have_exist_table_name,
    delete_table_info,
    update_table_info,
    query_ent_sub_manager_list,
    query_ent_primary_manager_detail,
    get_system_logs_list,
    query_workday_plan_list,
    insert_workday_plan,
    delete_workday_plan,
    insert_workday_plan_shop,
    delete_workday_plan_shop,
    query_workday_set_list,
    update_workday_set,
    delete_workday_set,
    insert_workday_set,
    insert_staff,
    set_aim_module_power,
    query_aim_module_power_detail,
    query_ent_prod_cat_list_tree_of_parent,
    query_user_module_list,
    set_user_module_rel,
    update_user_module,
    delete_user_module,
    export_table_qrcode_zip_post,
    get_wxpay_qrcode,
    lun_xun_trade_no,
    insert_order_web
}