<template>
    <div class="qx_main">
        <!-- <el-scrollbar style="height:100%;">
            
       </el-scrollbar> -->
        <div class="top">
            <span>选择人员</span>
            <el-input size="mini" v-model="filterPeople" style="width:220px;padding-right:20px;"></el-input>

            <el-button size="mini" type="danger" v-model="filterPeople" @click="searPeople">查询</el-button>

        </div>
        <div class="content">
            <!-- 选择人员 -->
            <div class="box first">
                <div class="coloum ">
                    <el-radio v-model="select_js" label="1">选择部门</el-radio>
                    <el-radio v-model="select_js" label="2">选择角色</el-radio>
                </div>
                <el-card class="box-card" >
                    <!-- style=" width: 300px; height: 500px; overflow: auto;" -->
                    <el-scrollbar style="height:100%;" v-show="select_js === '1'">
                        <el-tree :data="dep_data" show-checkbox node-key="id" highlight-current
                            :filter-node-method="filterNode" :props="defaultProps" @check-change="check_change"
                            :default-expand-all='true' ref="tree" @setCheckedNodes="getNodes">
                        </el-tree>

                    </el-scrollbar>
                    <el-collapse accordion v-show="select_js === '2'">
                        <el-collapse-item v-for="(item,index) in options_jiaose" :key="index">
                            <template slot="title">
                                {{item.role_group_name}}
                            </template>
                            <el-checkbox-group v-model="checked_jiaose" @change="options_CitiesChange_jiaose">
                                <el-checkbox v-for="(city,inde) in item.role_list" :label="city.role_id" :key="inde">
                                    {{city.role_name}}</el-checkbox>
                            </el-checkbox-group>
                        </el-collapse-item>
                    </el-collapse>
                </el-card>
            </div>

            <!-- 选择模块 -->
            <div class="box imports">
                <div class="coloum spanClass">
                    <span>选择权限</span>
                </div>
                <el-card class="box-card ">
                    <div style="width:110px;">
                        <el-checkbox-group v-model="qx_options_value" @change="handleCheckedCitiesChange">
                            <el-checkbox v-for="(city,index) in qx_options" :label="city.value" :key="index"
                                style="margin-top:10px;">{{city.name}}</el-checkbox>
                        </el-checkbox-group>
                    </div>

                    <div class="floatRight">
                        <p class="floatRight-p-top">ps:选择完权限请先保存</p>
                        <el-button size="mini" type="danger" @click="save_">添加权限</el-button>
                    </div>
                </el-card>
            </div>
            <!--人员列表 -->
            <div class="box last">
                <div class="coloum spanClass last">
                    <span>已选目录</span>
                    <div class="lastdiv">
                        <p>选择权限</p>
                        <el-select size="mini" style="width:120px;margin-left:20px;" v-model="search_value"
                            @change="search_value_change" placeholder="请选择权限">
                            <el-option v-for="it in qx_optionsALL" :key="it.value" :label="it.name" :value="it.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <el-card class="box-card">
                    <ul class="title">
                        <li>
                            <span>
                                <p>人员</p>
                            </span>
                            <span>
                                <p>部门</p>
                            </span>
                            <span>
                                <p>角色</p>
                            </span>
                            <span>
                                <p>权限设置</p>
                            </span>
                            <span></span>
                            <span></span>
                        </li>
                    </ul>
                    <ul class="title" v-for="(item,index) in list_form" :key="index">
                        <li :class="{color_two:index % 2 === 0}">
                            <span>
                                <p>{{item.staff_name}}<span v-if="item.active==0">(离职)</span></p>
                            </span>
                            <span>
                                <p>{{item.dept_name}}</p>
                            </span>
                            <span>
                                <p>{{item.role_name}}</p>
                            </span>
                            <span>
                                <p>{{item.module_id | module_id}}</p>
                            </span>
                            <span>
                                <p :style="{'color':item.active==0? '#999': 'red'}" @click="edit_click(item)">修改</p>
                            </span>
                            <span>
                                <p style="color:red" @click="del(item)">移除</p>
                            </span>
                        </li>
                    </ul>
                </el-card>
            </div>
        </div>
        <!-- 修改权限 -->
        <el-dialog title="修改权限" :visible.sync="edit" width="30%" :before-close="closeEdit"> <span>当前人员:</span> <span
                style="margin-left:30px;">{{edit_people_name}}</span>
            <div style="width:110px;padding-bottom:30px;">
                <el-checkbox-group v-model="qx_options_value_edit">
                    <el-checkbox v-for="(city,index) in qx_options" :label="city.value" :key="index"
                        style="margin-top:10px;">{{city.name}}</el-checkbox>
                </el-checkbox-group>
            </div>
            <el-button size="mini" type="danger" @click="edit = false">关闭</el-button>
            <el-button size="mini" type="danger" @click="save_edit">确定</el-button>
        </el-dialog>

    </div>
</template>

<script>
    const qx_options = [

        {
            name: '目标',
            value: '1-1'
        },
        {
            name: '点单',
            value: '1-2'
        },
        // {
        //     name: '烹饪',
        //     value: '1-3'
        // },
        // {
        //     name: '备餐',
        //     value: '1-4'
        // },
        // {
        //     name: '采购',
        //     value: '1-5'
        // },
        // {
        //     name: '库房',
        //     value: '1-6'
        // },
        // {
        //     name: '供应商',
        //     value: '1-7'
        // },
        {
            name: '打卡',
            value: '1-8'
        },
        // {
        //     name: '激励币',
        //     value: '1-9'
        // },
        {
            name: '会员',
            value: '1-10'
        },
        {
            name: '收银',
            value: '1-11'
        },
        // {
        //     name: '清洁',
        //     value: '1-12'
        // },
    ]

    import {
        set_aim_module_power,
        query_aim_module_power_detail,
        query_user_module_list,
        set_user_module_rel,
        update_user_module,
        delete_user_module
    } from '@api/pulic'
    import {
        query_user_list_of_role,
        query_user_list_of_roles
    } from '@api/public'
    import {
        query_role_group_info_list
    } from '@api/user'
    export default {
        filters: {
            // 
            module_id(val) {
                let data
                if (val) {
                    data = val.map(x => x.name).join('|')
                }
                return data
            }
        },
        props: {
            dep_data: {
                type: Array,
                default: () => []
            },
            read_only: {
                type: Number
            }
        },
        watch: {
            read_only(val) {
                this.qx_options_value = []
                this.query_user_module_list()
                this.checked_jiaose = []
                this.$refs.tree.setCheckedKeys([]);

            }
        },
        computed: {
            ent_id() {
                console.log('计算属性的中的ent_id',this.$store.state.ent_id)
                return this.$store.state.ent_id
            },
            qx_optionsALL() {
                let data = JSON.parse(JSON.stringify(this.qx_options))
                data.unshift({
                    name: '全部',
                    value: 'all'
                })
                return data
            }
        },
        data() {
            return {
                search_value: '', //搜索权限
                list_form_old: [],
                //修改
                qx_options_value_edit: [],
                edit: false,
                edit_people_name: "",
                edit_user_id: '',

                // 角色
                options_jiaose: [],
                checked_jiaose: [],
                checked_jiaosenew: [],

                //主体
                select_js: '1',
                default_checked_key: [],
                filterPeople: '',
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                qx_options: qx_options,
                qx_options_value: [],
                list_form: [],
                // 当前操作用户的userid
                user_id: '',
                staff_ids: [],
                inputvalueText: ''
            }
        },
        methods: {
            search_value_change() {
                let val = this.search_value
                // console.log(val, this.list_form_old);
                if (val === 'all') {
                    this.list_form = this.list_form_old
                    return
                }
                this.list_form = this.list_form_old.filter(item => {
                    return JSON.stringify(item.module_id).indexOf(val) !== -1
                })
            },
            // 搜索
            searvalue() {
                let val = this.inputvalueText
                console.log(val, this.list_form_old);
                this.list_form = this.list_form_old.filter(item => {
                    return JSON.stringify(item.module_id).indexOf(val) !== -1
                })
            },
            //  点击修改
            edit_click(val) {
                if(val.active == 0) {
                    this.$message({type: 'error', message: '当前人员已离职'})
                    return
                }
                this.edit = true
                this.edit_people_name = val.name
                this.edit_user_id = val.user_id
                this.qx_options_value_edit = val.module_id.map(x => x.value)
            },
            //确认修改权限   
            save_edit() {

                const data = {
                    ent_id: this.ent_id,
                    read_only: this.read_only + '',
                    user_id: this.edit_user_id,
                    module_ids: this.qx_options_value_edit.join(',')
                }
                console.log(data, '这是修改的数据');
                update_user_module({
                    data
                }).then(res => {
                    if (res.code === '200') {
                        this.$message.success('操作成功')
                        this.query_user_module_list()
                        this.edit = false
                    } else {
                        this.$message.error('操作失败')
                    }
                })

            },
            del(val) {
                console.log(val);
                const data = {
                    ent_id: this.ent_id,
                    read_only: this.read_only + '',
                    user_id: val.user_id
                }
                delete_user_module({
                    data
                }).then(res => {
                    console.log(res);
                    if (res.code === '200') {
                        this.$message.success('移除成功')
                        this.query_user_module_list()
                    }

                })
            },
            // 选择角色  获取底下的人
            options_CitiesChange_jiaose(val) {
                console.log(val, '这是啥=====');
                // val.forEach(item => {
                //     this.query_user_list_of_role(item)
                // })
                // console.log(val.join(','));
                let val_Str = val.join(',')
                this.query_user_list_of_roles(val_Str)

            },
            save_() {
                this.set_user_module_rel()
            },
            closeEdit() {
                this.edit = false
            },
            check_change() {
                // let list =  this.$refs.tree.getCheckedNodes()
                let list = this.$refs.tree.getCheckedKeys()
                this.staff_ids = list.filter(x => x.indexOf('BM') !== 0 && x.indexOf('MD') !== 0)
            },
            // 搜索人员
            searPeople() {
                this.$refs.tree.filter(this.filterPeople)
            },
            getNodes() {},
            filterNode(value, data) {
                if (!value) return true
                return data.label.indexOf(value) !== -1
            },
            handleCheckedCitiesChange(val) {

            },
            // 查询角色下的人- 已修改-舍弃
            query_user_list_of_role(role_id) {
                const data = {
                    ent_id: this.ent_id,
                    role_id
                }
                query_user_list_of_role({
                    data
                }).then(res => {
                    if (res.code === '200') {
                        res.body.data.forEach(x => {
                            this.checked_jiaosenew.push(x.user_id)
                        })

                    }
                })
            },
            // 查询角色下的人
            query_user_list_of_roles(role_id) {
                const data = {
                    ent_id: this.ent_id,
                    role_id
                }
                query_user_list_of_roles({
                    data
                }).then(res => {
                    if (res.code === '200') {
                      let checked_jiaosenew = []
                        res.body.data.forEach(x => {
                            checked_jiaosenew.push(x.user_id)
                        })
                      console.log('角色下的人员',checked_jiaosenew);
                      this.checked_jiaosenew = checked_jiaosenew
                    }
                })
            },
            // 设置用户与模块的关系
            set_user_module_rel() {
                console.log(this.checked_jiaose)
                let staff_ids = [...this.checked_jiaosenew, ...this.staff_ids]
                console.log(staff_ids, '--5656564648888947');
                console.log(this.list_form_old);
                let arr = []
                for (const i in staff_ids) {
                    for (const j in this.list_form_old) {
                        if (staff_ids[i] === this.list_form_old[j].user_id) {
                            arr.push(this.list_form_old[j])
                        }
                    }
                }
                
                if (arr.length > 0) {
                    return this.$message.warning(`${arr.map(x=>x.staff_name).join(',')}已经设置过权限无法保存,请修改`)
                }
                  
                const data = {
                    ent_id: this.ent_id,
                    read_only: this.read_only + '',
                    module_ids: this.qx_options_value.join(','),
                    staff_ids: staff_ids.join(',')
                }
                if (!data.module_ids) {
                    return this.$message.error('请选择权限')
                }
                if (!data.staff_ids) {
                    return this.$message.error('请选择人员')
                }
                return new Promise((resolve, reject) => {
                    set_user_module_rel({
                        data
                    }).then(res => {
                        console.log('设置权限', data, res);
                        if (res.code === '200') {
                            this.$message.success('保存成功')
                            this.query_user_module_list()
                        } else {
                            reject(res)
                        }
                    })
                })

            },
            // 人员模块列表
            query_user_module_list(user_id) {
                const data = {
                    ent_id: this.ent_id,
                    //1只读，2可写
                    read_only: this.read_only + '',
                    user_id: user_id || null
                }
                console.log(data, '121335455');
                return new Promise((resolve, reject) => {
                    query_user_module_list({
                        data
                    }).then(res => {
                        console.log(res, '获得的权限列表')
                        if (res.code === '200') {
                            res.body.data.forEach(item => {
                                item.module_id = item.module_id.split(',')
                                let arr = []
                                item.module_id.forEach(ite => {
                                    let dats = qx_options.find(x => {
                                        return x.value === ite
                                    })
                                    arr.push(dats)
                                })
                                item.module_id = arr
                            })
                            console.log(res.body.data);
                            this.list_form = res.body.data
                            this.list_form_old = res.body.data
                            resolve(res.body.data)
                        }
                    })
                })
            },
            // 更新用户的模块
            update_user_module() {

            },
            // 删除权限列表中的人
            delete_user_module() {
                // ent_id,read_only,user_id
            },
            // 查询人员角色
            query_role_group_info_list() {
                const data = {
                    ent_id: this.ent_id,
                    active: '1'
                }
                query_role_group_info_list({
                    data
                }).then(res => {
                    console.log(res, '角色信息');
                    if (res.code === '200') {
                        this.options_jiaose = res.body.data
                    }
                })
            }

        },
        created() {
            this.ent_id = this.$store.state.ent_id;
            this.user_id = sessionStorage.getItem('user_id');
            this.query_user_module_list();
            this.query_role_group_info_list();
        }
    }
    /**[

        {
            name: '目标',
            value: '1-1'
        },
        {
            name: '点单',
            value: '1-2'
        },
        {
            name: '烹饪',
            value: '1-3'
        },
        {
            name: '备餐',
            value: '1-4'
        },
        {
            name: '清洁',
            value: '1-5'
        },
        {
            name: '库房',
            value: '1-6'
        },
        {
            name: '采购',
            value: '1-7'
        },
        {
            name: '研发',
            value: '1-8'
        },
        {
            name: '质检',
            value: '1-9'
        },
        {
            name: '人力',
            value: '1-10'
        },
        {
            name: '财务',
            value: '1-11'
        },
        {
            name: '行政',
            value: '1-12'
        },
    ] */
</script>

<style lang="scss" >
.first>.box-card  {
    .el-card__body{
        width: 300px;
        height: 460px;
        overflow-y: auto;
    }
}
.last > .box-card  {
    .el-card__body{
        margin: 0 auto;
        width: 1000px;
        height: 500px;
        overflow-y: auto;
    }
}
</style>

<style scoped lang="scss">
    .qx_main {
        display: flex;
        margin-top: 20px;
        padding: 10px 20px 20px 20px;
        flex: 1;
        flex-flow: column nowrap;
        background: #fff;
        border-radius: 4px;

        >.top {
            display: flex;
            vertical-align: middle;

            >span {
                display: inline-flex;
                font-size: 16px;
                line-height: 30px;
                color: #888;
                padding-right: 10px;
            }

            >.floatRight {
                flex: 1;
                text-align: right;
            }
        }

        >.content {
            display: flex;
            flex: 1;
            margin-top: 30px;
            flex-flow: row nowrap;
            justify-content: space-between;

            >.box {
                display: flex;
                flex-grow: 1;
                margin-right: 14px;
                margin-left: 14px;
                flex-flow: column nowrap;

                &.first {
                    flex-shrink: 0;
                    flex-grow: 0;
                    flex-basis: 320px;
                }

                &.imports {
                    width: 200px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    flex-basis: 200px;

                }

                >.coloum {
                    height: 40px;

                    >span {
                        float: left;
                    }

                    .lastdiv {
                        width: 60%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                }

                >.box-card {
                    flex: 1;

                    .title {
                        display: flex;
                        flex-flow: column nowrap;
                        justify-content: flex-start;

                        >li {
                            display: flex;
                            min-height: 40px;
                            line-height: 40px;
                            justify-content: flex-start;

                            &.color_two {
                                background: #ebebeb;
                            }

                            >span {
                                font-size: 14px;
                                color: #888;
                                text-align: center;

                                &:nth-child(1) {
                                    width: 100px;
                                }

                                &:nth-child(2) {
                                    width: 200px;
                                }

                                &:nth-child(3) {
                                    width: 200px;
                                }

                                &:nth-child(4) {
                                    flex: 1;
                                }

                                &:nth-child(5) {
                                    width: 60px;
                                }

                                &:nth-child(6) {
                                    width: 60px;
                                }
                            }
                        }
                    }

                    .floatRight {
                        padding-top: 30px;
                        display: flex;
                        flex-flow: column nowrap;
                        align-items: center;

                        //选择完权限请先保存
                        .floatRight-p-top {
                            width: 100%;
                            color: #888;
                            font-size: 14px;
                        }

                        .el-button {
                            width: 80px;
                            margin-top: 20px;
                            text-align: center;
                        }
                    }
                }

                &.last {
                    ::v-deep .el-card__body {
                        padding: 0;
                    }

                }
            }
        }

        ::v-deep .el-scrollbar__wrap {
            overflow: scroll;
            border: 1px solid #fff;
            height: calc(100% + 17px);
        }

        ::v-deep .el-checkbox-group {
            display: flex;
            flex-flow: column nowrap;
        }

        ::v-deep .el-collapse-item__header {
            height: 24px;
        }

        .spanClass {
            font-size: 14px;
            color: black;
        }

    }
</style>