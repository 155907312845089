<template>
    <div class="qx_main">
        <!-- <el-scrollbar style="height:100%;">
            
       </el-scrollbar> -->
        <div class="top">
            <span>选择人员</span>
            <el-input size="mini" v-model="filterPeople" style="width:220px;padding-right:20px;"></el-input>

            <el-button size="mini" type="danger" v-model="filterPeople" @click="searPeople">查询</el-button>


            <el-radio-group v-model="looktype" style="margin-left:50px;" @change="chanLA">
                <el-radio :label="1">设置查看权限(看自己)</el-radio>
                <el-radio :label="2">设置查看权限(看全部)</el-radio>
            </el-radio-group>

        </div>
        <div class="content">
            <!-- 选择人员 -->
            <div class="box first">
                <div class="coloum ">
                    <el-radio v-model="select_js" label="1">选择部门</el-radio>
                    <el-radio v-model="select_js" label="2">选择角色</el-radio>
                </div>
                <el-card class="box-card">
                    <el-scrollbar style="height:100%;" v-show="select_js === '1'">
                        <el-tree :data="threedata" show-checkbox node-key="id" highlight-current
                            :filter-node-method="filterNode" :props="defaultProps" @check-change="check_change"
                            :default-expand-all='true' ref="tree" @setCheckedNodes="getNodes">
                        </el-tree>

                    </el-scrollbar>
                    <el-collapse accordion v-show="select_js === '2'">
                        <el-collapse-item v-for="(item,index) in options_jiaose" :key="index">
                            <template slot="title">
                                {{item.role_group_name}}
                            </template>
                            <el-checkbox-group v-model="checked_jiaose" @change="options_CitiesChange_jiaose">
                                <el-checkbox v-for="(city,inde) in item.role_list" :label="city.role_id" :key="inde">
                                    {{city.role_name}}</el-checkbox>
                            </el-checkbox-group>
                        </el-collapse-item>
                    </el-collapse>
                </el-card>
            </div>

            <!-- 选择模块 -->
            <div class="box imports">
                <div class="coloum spanClass">
                    <span>选择权限</span>
                </div>
                <el-card class="box-card ">
                    <div style="width:110px;">
                        <el-checkbox-group v-model="qx_options_value" @change="handleCheckedCitiesChange">
                            <el-checkbox v-for="(city,index) in qx_options" :label="city.report_id" :key="index"
                                style="margin-top:10px;">{{city.report_name}}</el-checkbox>
                        </el-checkbox-group>
                    </div>

                    <div class="floatRight">
                        <p class="floatRight-p-top">ps:选择完权限请先保存</p>
                        <el-button size="mini" type="danger" @click="save_">添加权限</el-button>
                    </div>
                </el-card>
            </div>
            <!--人员列表 -->
            <div class="box last">
                <div class="coloum spanClass last">
                    <span>已选目录</span>
                    <div class="lastdiv">
                        <p>选择权限</p>
                        <el-select size="mini" style="width:120px;margin-left:20px;" v-model="search_value"
                            @change="search_value_change" placeholder="请选择权限">
                            <el-option v-for="it in qx_optionsALL" :key="it.value" :label="it.report_name"
                                :value="it.report_id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <el-card class="box-card">
                    <ul class="title">
                        <li>
                            <span>
                                <p>人员</p>
                            </span>
                            <span>
                                <p>部门</p>
                            </span>
                            <span>
                                <p>角色</p>
                            </span>
                            <span>
                                <p>权限设置</p>
                            </span>
                            <span></span>
                            <span></span>
                        </li>
                    </ul>
                    <ul class="title" v-for="(item,index) in list_form" :key="index">
                        <li :class="{color_two:index % 2 === 0}">
                            <span>
                                <p>{{item.staff_name}}<span v-if="item.active==0">(离职)</span></p>
                            </span>
                            <span>
                                <p>{{item.dept_name}}</p>
                            </span>
                            <span>
                                <p>{{item.role_name}}</p>
                            </span>
                            <span>
                                <p>{{item.report_id | report_id}}</p>
                                <!-- <p>{{item.report_id }}</p> -->
                            </span>
                            <span>
                                <p :style="{'color':item.active==0? '#999': 'red'}" @click="edit_click(item)">修改</p>
                            </span>
                            <span>
                                <p style="color:red" @click="del(item)">移除</p>
                            </span>
                        </li>
                    </ul>
                </el-card>
            </div>
        </div>
        <!-- 修改权限 -->
        <el-dialog title="修改权限" :visible.sync="edit" width="30%" :before-close="closeEdit"> <span>当前人员:</span> <span
                style="margin-left:30px;">{{edit_people_name}}</span>
            <div style="width:110px;padding-bottom:30px;">
                <el-checkbox-group v-model="qx_options_value_edit">
                    <el-checkbox v-for="(city,index) in qx_options" :label="city.report_id" :key="index"
                        style="margin-top:10px;">{{city.report_name}}</el-checkbox>
                </el-checkbox-group>
            </div>
            <el-button size="mini" type="danger" @click="edit = false">关闭</el-button>
            <el-button size="mini" type="danger" @click="save_edit">确定</el-button>
        </el-dialog>

    </div>
</template>

<script>
    const qx_options = [

        {
            name: '点单分析',
            value: '1-1'
        },
        {
            name: '备餐分析',
            value: '1-2'
        },
        {
            name: '烹饪分析',
            value: '1-3'
        }
    ]

    import {
        set_aim_module_power,
        query_aim_module_power_detail,
        query_user_module_list,
        set_user_module_rel,
        update_user_module,
        delete_user_report,
        update_user_report,
        query_report_info_list,
        query_user_report_list,
        set_user_report_rel,
        delete_user_module
    } from '@api/pulic'
    import {
        query_user_list_of_role,

    } from '@api/public'
    import {
        query_role_group_info_list,

    } from '@api/user'
    export default {
        filters: {
            // 
            report_id(val) {
                let data = []
                if (val) {
                  console.log('===========',val);
                    // data = val.map(x => x.report_name).join('|')

                    for (let a in val) {
                        data.push(val[a].report_name)
                    }
                    data = data.join('|')

                }
                return data
            }
        },
        props: {
            dep_data: {
                type: Array,
                default: () => []
            },
            read_only: {
                type: Number
            }
        },
        watch: {
            read_only(val) {
                this.qx_options_value = []
                this.query_user_module_list()
                this.checked_jiaose = []

            },
            looktype(val) {
                this.qx_options_value = []
                this.query_user_module_list()
                this.checked_jiaose = []

            }
        },
        computed: {
            ent_id() {
                console.log(this.$store.state.ent_id)
                return this.$store.state.ent_id
            },
            qx_optionsALL() {
                let data = JSON.parse(JSON.stringify(this.qx_options))
                data.unshift({
                    report_name: '全部',
                    report_id: ''
                })
                return data
            }

        },
        data() {
            return {
                threedata:[],
                threedata_old:[],
                looktype: 1,
                search_value: '', //搜索权限
                list_form_old: [],
                //修改
                qx_options_value_edit: [],
                edit: false,
                edit_people_name: "",
                edit_user_id: '',

                // 角色
                options_jiaose: [],
                checked_jiaose: [],
                checked_jiaosenew: [],

                //主体
                select_js: '1',
                default_checked_key: [],
                filterPeople: '',
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                qx_options:[], //qx_options,
                qx_options_value: [],
                list_form: [],
                // 当前操作用户的userid
                user_id: '',
                staff_ids: [],
                inputvalueText: ''
            }
        },
        methods: {
            chanLA() {
                this.query_user_module_list()
                this.qx_options_value = []
                this.threedata=this.threedata_old

                // for (const i in this.$props.dep_data) {
                //     for (const k in this.$props.dep_data[i].children) {

                //         this.$refs.tree.setChecked(this.$props.dep_data[i].children[k].id, false)

                //     }
                // }
                // this.$nextTick(() => {
                // this.$refs.tree.setCheckedKeys([])
                // this.$refs.tree.setChecked( , false)




                // })

            },
            search_value_change() {
                console.log(this.search_value)

                let val = this.search_value
                // console.log(val, this.list_form_old);
                if (val === 'all') {
                    this.list_form = this.list_form_old
                    return
                }
                this.list_form = this.list_form_old.filter(item => {
                    console.log(item)
                    return JSON.stringify(item.report_id).indexOf(val) !== -1
                })

            },
            // 搜索
            searvalue() {
                let val = this.inputvalueText
                console.log(val, this.list_form_old);
                this.list_form = this.list_form_old.filter(item => {
                    return JSON.stringify(item.report_id).indexOf(val) !== -1
                })
            },
            //  点击修改
            edit_click(val) {
                if(val.active == 0) {
                    this.$message({type: 'error', message: '当前人员已离职'})
                    return
                }
                this.edit = true
                this.edit_people_name = val.name
                this.edit_user_id = val.user_id
                this.qx_options_value_edit = val.report_id.map(x => x.report_id)
            },
            //确认修改权限   
            save_edit() {

                const data = {
                    ent_id: this.ent_id,
                    look_type: this.looktype + '',
                    user_id: this.edit_user_id,
                    report_ids: this.qx_options_value_edit.join(',')
                }
                console.log(data, '这是修改的数据');
                update_user_report({
                    data
                }).then(res => {
                    if (res.code === '200') {
                        this.$message.success('操作成功')
                        this.query_user_module_list()
                        this.edit = false
                    } else {
                        this.$message.error('操作失败')
                    }
                })

            },
            del(val) {
                console.log(val);
                const data = {
                    ent_id: this.ent_id,
                    look_type: this.looktype + '',
                    user_id: val.user_id
                }
                delete_user_report({
                    data
                }).then(res => {
                    console.log(res);
                    if (res.code === '200') {
                        this.$message.success('移除成功')
                        this.query_user_module_list()
                    }

                })
            },
            // 选择角色  获取底下的人
            options_CitiesChange_jiaose(val) {
                console.log(val, '这是啥=====');
                val.forEach(item => {
                    this.query_user_list_of_role(item)
                })

            },
            save_() {
                this.set_user_module_rel()
            },
            closeEdit() {
                this.edit = false
            },
            check_change() {
                // let list =  this.$refs.tree.getCheckedNodes()
                let list = this.$refs.tree.getCheckedKeys()
                this.staff_ids = list.filter(x => x.indexOf('BM') !== 0 && x.indexOf('MD') !== 0)
            },
            // 搜索人员
            searPeople() {
                this.$refs.tree.filter(this.filterPeople)
            },
            getNodes() {},
            filterNode(value, data) {
                if (!value) return true
                return data.label.indexOf(value) !== -1
            },
            handleCheckedCitiesChange(val) {

            },
            // 查询角色下的人
            query_user_list_of_role(role_id) {
                const data = {
                    ent_id: this.ent_id,
                    role_id
                }
                this.checked_jiaosenew = []
                query_user_list_of_role({
                    data
                }).then(res => {
                    if (res.code === '200') {
                        res.body.data.forEach(x => {
                            this.checked_jiaosenew.push(x.user_id)
                        })

                    }
                })
            },
            // 设置用户与模块的关系
            set_user_module_rel() {
                console.log(this.checked_jiaose)
                let staff_ids = [...this.checked_jiaosenew, ...this.staff_ids]
                console.log(this.list_form_old);
                let arr = []
                for (const i in staff_ids) {
                    for (const j in this.list_form_old) {
                        if (staff_ids[i] === this.list_form_old[j].user_id) {
                            arr.push(this.list_form_old[j])
                        }
                    }
                }
                if (arr.length > 0) {
                    return this.$message.warning(`${arr.map(x=>x.staff_name).join(',')}已经设置过权限无法保存,请修改`)
                }
                const data = {
                    ent_id: this.ent_id,
                    look_type: this.looktype + '',
                    report_ids: this.qx_options_value.join(','),
                    staff_ids: staff_ids.join(',')
                }
                if (!data.staff_ids) {
                    return this.$message.error('请选择人员')
                }
                if (!data.report_ids) {
                    return this.$message.error('请选择权限')
                }
                return new Promise((resolve, reject) => {
                    set_user_report_rel({
                        data
                    }).then(res => {
                        console.log('设置权限', data, res);
                        if (res.code === '200') {
                            this.$message.success('保存成功')
                            this.query_user_module_list()
                            this.checked_jiaosenew = []
                        } else {
                            reject(res)
                        }
                    })
                })

            },
            // 人员模块列表
            query_user_module_list(user_id) {
                const data = {
                    ent_id: this.ent_id,
                    //1只读，2可写
                    look_type: this.looktype + '',
                    user_id: user_id || null
                }
                console.log(data, '121335455');
                return new Promise((resolve, reject) => {
                    query_user_report_list({
                        data
                    }).then(res => {
                        let data_1s = JSON.parse(JSON.stringify(res.body.data))
                        console.log('======data_1s====',data_1s);
                        console.log(res, '获得的权限列表') //report_id是undefined报错
                        if (res.code === '200') {
                            res.body.data.forEach(item => {
                                console.log('==============',item);
                                let report_id_arr = item.report_id.split(',')
                                console.log('report_id_arr--------',report_id_arr);

                                item.report_id = report_id_arr
                                console.log('---item.report_id---',item.report_id);


                                let arr = []
                                item.report_id.forEach(ite => {
                                    console.log(ite, '12341')
                                    console.log('this.qx_options',this.qx_options);
                                    let dats = this.qx_options.find(x => {
                                        console.log(x,ite, x.report_id, '1234')
                                        return x.report_id === ite
                                    })
                                    arr.push(dats)
                                })
                                console.log(arr, '123456')
                                item.report_id = arr
                            })
                            console.log(res.body.data);
                            this.list_form = res.body.data
                            this.list_form_old = res.body.data
                            resolve(res.body.data)
                        }
                    })
                })
            },
            // 更新用户的模块
            update_user_module() {

            },
            // 删除权限列表中的人
            delete_user_module() {
                // ent_id,read_only,user_id
            },
            query_report_info_list() {
                const data = {
                    // ent_id: this.ent_id,
                    // active: '1'
                }


                query_report_info_list({
                    data
                }).then(res => {
                    console.log(res, '角色信息2');
                    if (res.code === '200') {
                        this.qx_options = res.body.data
                        this.query_user_module_list();
                    }
                })
            },
            // 查询人员角色
            query_role_group_info_list() {
                const data = {
                    ent_id: this.ent_id,
                    active: '1'
                }
                query_role_group_info_list({
                    data
                }).then(res => {
                    console.log(res, '角色信息');
                    if (res.code === '200') {
                        this.options_jiaose = res.body.data
                    }
                })
            }

        },
        created() {
            this.ent_id = this.$store.state.ent_id;
            this.user_id = sessionStorage.getItem('user_id');
            // this.query_user_module_list();
            this.query_role_group_info_list();
            this.query_report_info_list()
            this.threedata_old=JSON.parse(JSON.stringify(this.$props.dep_data))
            this.threedata=JSON.parse(JSON.stringify(this.$props.dep_data))
        }
    }
</script>

<style scoped lang="scss">
    .qx_main {
        display: flex;
        margin-top: 20px;
        padding: 10px 20px 20px 20px;
        flex: 1;
        flex-flow: column nowrap;
        background: #fff;
        border-radius: 4px;

        >.top {
            display: flex;
            vertical-align: middle;

            >span {
                display: inline-flex;
                font-size: 16px;
                line-height: 30px;
                color: #888;
                padding-right: 10px;
            }

            >.floatRight {
                flex: 1;
                text-align: right;
            }
        }

        >.content {
            display: flex;
            flex: 1;
            margin-top: 30px;
            flex-flow: row nowrap;
            justify-content: space-between;

            >.box {
                display: flex;
                flex-grow: 1;
                margin-right: 14px;
                margin-left: 14px;
                flex-flow: column nowrap;

                &.first {
                    flex-shrink: 0;
                    flex-grow: 0;
                    flex-basis: 320px;
                }

                &.imports {
                    width: 200px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    flex-basis: 200px;

                }

                >.coloum {
                    height: 40px;

                    >span {
                        float: left;
                    }

                    .lastdiv {
                        width: 60%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                }

                >.box-card {
                    flex: 1;

                    .title {
                        display: flex;
                        flex-flow: column nowrap;
                        justify-content: flex-start;

                        >li {
                            display: flex;
                            min-height: 40px;
                            line-height: 40px;
                            justify-content: flex-start;

                            &.color_two {
                                background: #ebebeb;
                            }

                            >span {
                                font-size: 14px;
                                color: #888;
                                text-align: center;

                                &:nth-child(1) {
                                    width: 100px;
                                }

                                &:nth-child(2) {
                                    width: 200px;
                                }

                                &:nth-child(3) {
                                    width: 200px;
                                }

                                &:nth-child(4) {
                                    flex: 1;
                                }

                                &:nth-child(5) {
                                    width: 60px;
                                }

                                &:nth-child(6) {
                                    width: 60px;
                                }
                            }
                        }
                    }

                    .floatRight {
                        padding-top: 30px;
                        display: flex;
                        flex-flow: column nowrap;
                        align-items: center;

                        //选择完权限请先保存
                        .floatRight-p-top {
                            width: 100%;
                            color: #888;
                            font-size: 14px;
                        }

                        .el-button {
                            width: 80px;
                            margin-top: 20px;
                            text-align: center;
                        }
                    }
                }

                &.last {
                    ::v-deep .el-card__body {
                        padding: 0;
                    }

                }
            }
        }

        ::v-deep .el-scrollbar__wrap {
            overflow: scroll;
            border: 1px solid #fff;
            height: calc(100% + 17px);
        }

        ::v-deep .el-checkbox-group {
            display: flex;
            flex-flow: column nowrap;
        }

        ::v-deep .el-collapse-item__header {
            height: 24px;
        }

        .spanClass {
            font-size: 14px;
            color: black;
        }

    }
</style>