<template>
  <div class="divs">
    <!-- <Settings :listdatas="listdatas"></Settings> -->
    <div class="top">
      <div class="left">
          <span :class="{'active': ck === 1}" @click="ck=1"><p>仅查看</p></span>
          <span :class="{'active': ck === 2}" @click="ck=2"><p>查看和编辑</p></span>
           <span :class="{'active': ck === 3}" @click="ck=3"><p>B端分析</p></span>
      </div>

    </div>
    <Maink :dep_data="message" :read_only.sync="ck" v-if="ck!=3"  ></Maink>
      <MainkS :dep_data="message" :read_only.sync="ck" v-else ></MainkS>
  </div>
</template>

<script>
// let id = 1000
// import Settings from '@c/Home/Settings'
import Maink from './main'
import MainkS from './main2'
import { query_shop_info_list} from '@api/store'
import { query_dept_staff_tree_aim_not  } from '@api/foodqiye'
import { query_dept_staff_tree_aim  } from '@api/pulic'
export default {
  components: {
   // Settings
   MainkS,
   Maink
  },
  computed: {
    info(){
      return this.$store.state.ent_id
    },
    user_id() {
      return this.$store.state.user_id
    }
  },
  watch:{
    ck(){
       this.getmessage()
    }
  },
  data() {
    return {
      t:true,
      ck:1,
      options_md: [],
      message: [],
      value_md: '',
      listdatas: [
        { name: '全模块', link: 'FullModule' },
        { name: '目标', link: 'Target' },
        { name: '点单', link: 'Order' },
        { name: '烹饪', link: 'Cooking' },
        { name: '备餐', link: 'MealPreparation' },
        { name: '清洁', link: 'Clear' },
        { name: '库房', link: 'StorageRoom' },
        { name: '采购', link: 'Purchase' },
        { name: '研发', link: 'ResearchDevelopment' },
        { name: '质检', link: 'QualityTesting' },
        { name: '人力', link: 'HumanResources' },
        { name: '财务', link: 'Finance' },
        { name: '行政', link: 'Administration' }
      ]
    }
  },
  created(){
    this.getmessage()
  },
  methods: {
    getmessage() {
      const param = {
        ent_id:this.info
      }
      const param1 ={
        ent_id:this.info,
      }
      console.log(123);
       query_dept_staff_tree_aim({data:param1}).then(res=>{
         if(res.code === '10168'){
           console.log(res,'lllllk')
           this.message = res.body.data
         }
       })
      //获取组织架构
      query_dept_staff_tree_aim_not({data:param1}).then(res=>{
      })
      // 获取门店信息
      query_shop_info_list({data:param}).then(res=>{
        this.options_md = res.body.data.map(x=>{
          return {
            shop_name:x.shop_name,
            shop_id:x.shop_id
          }
        })
      })
    }
  },

}
</script>
<style lang="scss" scoped>
.divs {
  font-size: 20px;
  color: red;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  .top{
    width: 100%;
    height:80px;
    background: #fff;
    border-radius: 6px;
    line-height: 80px;
    display: inline-flex;
    justify-content: space-between;
    >.left{
      >span{
      width: 100px;
      display: inline-block;
      height: 40px;
      vertical-align: middle;
      border: 1px solid red;
      border-radius: 8px;
      margin-left: 20px;
      color: red;
      >p{
        font-size: 16px;
        line-height: 40px;
        text-align: center;
      }
      &.active{
        color: #fff;
        background:red;
      }
    }
    }

  }
}
</style>
